:global .action-cell {
    overflow: visible;

    .ap-buttons-wrapper {
      .ap-menu-buttons {
        .ap-button {
          padding: 4px;

          span.Appkit4-icon:last-child{
            display: none;
          }
        }
      }

      .ap-button-dropdown {
        z-index: 5;
      }
    }
  }
