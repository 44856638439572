@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";

@import "@appkit4/styles/themes/appkit.orange.min.css";
@import "@appkit4/react-components/dist/styles/appkit4-react.min.css";
@import "./assets/fonts/font.scss";
@import "./variables.scss";

html,
body,
#root {
  height: 100%;

  .full-height {
    height: 100%;
  }

  .content-wrapper {
    height: calc(100% - $app-header-height);
    flex-grow: 1;
  }

  .Toastify__toast-container {
    padding: 0;
  }

  .Toastify__toast {
    min-height: auto;
    padding: 0;
    border-radius: 8px;
  }

  .Toastify__toast-body {
    padding: 0;
  }

  .ap-notification {
    width: 320px;
    margin: 0;
  }

  .ap-flex {
    display: flex;
  }

  //utility classes
  .ap-full-width {
    width: 100%;
  }
  .ap-align-items-center {
    align-items: center;
  }
  .ap-justify-content-center {
    justify-content: center;
  }
  .ap-justify-content-end {
    justify-content: end;
  }
  .ap-justify-content-space-between {
    justify-content: space-between;
  }
  .ap-flex-direction-column {
    flex-direction: column;
  }
  .ap-flex-nowrap {
    flex-wrap: nowrap;
  }
  .ap-position-relative {
    position: relative;
  }

  .ap-cursor-pointer {
    cursor: pointer;
  }

  .field-error,
  .ap-field.error .ap-field-content {
    color: #c52a1a;
    font-size: 12px;
    margin-top: 5px;
  }

  .ap-flex-gap-default {
    gap: $spacing-4;
  }

  .scroll-bar {
    // scrollbar styles
    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: $neutral-05;
      border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: $neutral-05;
    }
  }
}
