@import "../../variables.scss";

.wrapper {
  :global .ap-table table tbody tr {
    transition: none;
  }

  td {
    vertical-align: middle;
  }
}

.striped {
  tr:nth-child(odd) {
    background-color: $color-background-selected;
  }
}

.headerCell {
  display: flex;
  align-items: center;
  padding: 12px;
}

.alignRight {
  justify-content: flex-end;
}

.hidden {
  opacity: 0;
}

.container {
  overflow: auto;
}

.drag {
  cursor: grab;
}

.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
