.label {
  font-size: 16px;
  padding-bottom: 8px;
}

.value {
  padding: 0 0 16px 8px;
  font-weight: 500;
}

.divider {
  width: 100%;
  margin-bottom: 16px;
  border-bottom: 1px solid #dddddd;
}

.open {
  position: absolute;
  right: 8px;
  top: 16px;
}
