@import "../../../../variables.scss";

.tile {
  position: relative;
  flex: 1 1 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px 14px 16px;
  height: 64px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;

  &:hover {
    background-color: #fff5e7;
  }

  &.active {
    background-color: #fff5e7;
  }

  & > div {
    position: absolute;
    width: 100%;
    height: 4px;
    left: 0;
    bottom: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}
