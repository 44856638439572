.modal {
  width: 1100px;
  height: 620px;
}

.notePreview {
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  padding: 10px;
}

.editorContainer {
  height: 100%;
  padding: 15px;
}

:global .ap-modal .ck-content {
  height: inherit;
}
