.navMenu {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    gap: 24px;
    padding: 0 24px;

    .navItem {
        display: flex;
        align-items: center;
        box-sizing: border-box;

        a {
          color: black;
          font-size: 14px;
          font-weight: 500;
        }

        &.active {
            border-bottom: .25rem solid #2d2d2d;
        }
    }
}