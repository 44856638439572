@import "../../../../../variables.scss";

.iconButton {
  height: 24px !important;
  padding: 0 !important;
  margin-right: $spacing-3;

  &:focus-within {
    box-shadow: none !important;
  }
}

.primary {
  span {
    color: $color-text-primary !important;
  }
}

.pulse {
  animation: pulse-animation 2s 3;
}

@keyframes pulse-animation {
  0% {
    outline: 1px solid rgba(208, 74, 2, 0.12);
  }
  100% {
    outline: 12px solid rgba(208, 74, 2, 0.1);
  }
}

.actions {
  position: absolute;
  right: $spacing-3;
}
