.c4t-header {
  font-family: "PwC Helvetica Neue";
  display: flex !important;
  flex-direction: row;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 3rem !important;
  position: sticky;
  top: 0;

  :global(.ap-header-title) {
    margin-left: 15px;
    padding-left: 15px;
    border-left: 1px solid gray;
  }

  :global(.ap-avatar) {
    font-size: 12px;
  }

  :global(.ap-header-content) {
    position: relative !important;
    flex-grow: 1;
    height: 100%;
  }
}
