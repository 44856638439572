@import "../../../../../variables.scss";

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  position: relative;
  flex-grow: 1;
  overflow: auto;
  background-color: $neutral-01;
  padding: $spacing-7;
  border-radius: 16px 0 0;
}

.accordionItem {
  border: 1px solid $color-background-border;
  border-bottom: 0;
  border-radius: 0 !important;
  margin-bottom: 0px !important;

  &:first-child {
    border-radius: 4px 4px 0 0 !important;
  }

  &:last-child {
    border-radius: 0 0 4px 4px !important;
    border: 1px solid $color-background-border;
  }
}

.breadcrumb {
  margin-top: -16px;
  padding-bottom: $spacing-3;
}
