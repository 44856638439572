@import "../../../variables.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sections {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: $spacing-6;
  height: inherit;
}
