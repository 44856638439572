@import "../../variables.scss";

.footer {
  width: 100%;
  min-height: $footer-height;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
