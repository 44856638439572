@import "../../../../variables.scss";

$indicator-width: 40px;

.wrapper {
  max-width: calc(100% - $indicator-width - $spacing-6);
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.8ms;
}

.label {
  font: $typography-body;
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $neutral-14;
}

.label-focused {
  position: absolute;
  top: 12px;

  span {
    font: $typography-body-xs;
  }
}

.required {
  &::after {
    content: "";
    position: absolute;
    top: 4px;
    right: -8px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: $color-text-error;
  }
}

.error {
  color: $color-text-error;
}
