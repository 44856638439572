@import "..?../../../../../../variables.scss";

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.coordinates {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $spacing-6;
}

.content {
  flex-grow: 1;
  overflow: auto;
  background-color: $neutral-01;
  padding: $spacing-7;
  border-radius: 16px 0 0;
}

.tile {
  text-align: center;
  padding: $spacing-3;
  background-color: $neutral-03;
}

.preview {
  overflow: auto;
  max-height: 100%;
}

.breadcrumb {
  margin-top: -16px;
  padding-bottom: $spacing-3;
}
