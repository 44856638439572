@import "../../../variables.scss";

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .search {
    width: 50%;
    padding: $spacing-3;
    padding-left: 0;
  }

  .roleFilter {
    width: 50%;
    padding: 0.5rem;
  }

  .filter {
    display: flex;
    width: 50%;
  }
}
