@import "../../variables.scss";

.spinnerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $spacing-2;
  width: 15rem;
  height: 5rem;
  :global .spinner-border {
    width: 3rem;
    height: 3rem;
  }
}

.spinnerInModal {
  margin: 1rem -1.5rem;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.7;
  background-color: $neutral-01;
  z-index: 99;
}
