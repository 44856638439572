@import "../../../../variables.scss";

.modal {
  width: $default-modal-width;

  .upload {
    :global .ap-fileupload-wrapper .ap-fileupload-footer {
      display: none;
    }
  }
}
