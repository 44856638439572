@import "../../../../variables.scss";

$input-height: 30px;
$box-shadow-primary: inset 0 0 0 0.0625rem $color-background-primary,
  0 0 0 0.25rem rgba($color-background-primary-rgb, $opacity-3);

.input {
  width: 100%;
  height: $input-height;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  padding: 0 4px;
  margin-left: -4px;
}

.input:focus {
  border: none;
  box-shadow: $box-shadow-primary;
  background-color: $neutral-01;
}

.input:focus-visible {
  border: none;
  outline: none;
  box-shadow: $box-shadow-primary;
  background-color: $neutral-01;
}

.multiSelect {
  margin: -12px 0;
}
