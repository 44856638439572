@import "../../variables.scss";

.root {
  height: 100%;
}

.root-expanded {
  height: calc(90vh - 90px);
}

.apGrid {
  & :global {
    --ag-alpine-active-color: #d04a02;
    --ag-icon-font-color-tree-closed: transparent;
    // --ag-icon-image-tree-closed: url('../../../../assets/icons/appkit/right-chevron.svg');
    --ag-icon-font-color-tree-open: transparent;
    // --ag-icon-image-tree-open: url('../../../../assets/icons/appkit/down-chevron.svg');
    font-family: "PwC Helvetica Neue", "Helvetica Neue", sans-serif;

    .ag-theme-alpine {
      --ag-alpine-active-color: #d04a02;
    }

    .ag-checkbox-input-wrapper.ag-checked::after {
      color: #d04a02 !important;
    }

    .ag-icon-tree-closed:hover,
    .ag-icon-tree-open:hover {
      color: transparent !important;
    }

    .ag-root-wrapper {
      // border: none;
      border-radius: 8px;
      border-top: 1px solid #babfc7;
    }

    //Uncomment to remove sidebar border
    // .ag-side-bar {
    //   border: none
    // }

    .ag-header {
      //Uncomment in case of additional top toolbar
      // border: none;
      background-color: #fff;

      .ag-header-cell-text {
        font-size: 16px;
        font-weight: 500 !important;
      }
    }

    .ag-header-cell-resize {
      color: #1d2226;
    }

    .ag-row {
      background-color: transparent;
      //border-bottom: none;
      z-index: 1;
    }

    .ag-row-focus {
      z-index: 2;
    }

    .ag-row-no-focus {
      z-index: 1;
    }

    .ag-row-odd,
    .ag-row-focus {
      background-color: transparent;
    }

    .ag-row-focus {
      z-index: 2;
    }

    .ag-row-no-focus {
      z-index: 1;
    }

    .ag-row-level-1 {
      background-color: #f3f3f3;
    }

    .ag-row-level-1 .child-padding {
      padding-left: 30px !important;
    }

    .ag-cell {
      height: 48px;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 16px;
    }

    .cell-error {
      background-color: rgba($color-text-error-rgb, $opacity-3) !important;
    }

    .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(
        .ag-cell-range-single-cell
      ) {
      border-right: none;
    }

    //Removing border of right pinned columns like "actions"
    // .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
    //   border-left: none;
    // }

    .ag-pinned-left-header {
      border-right: none;
    }

    // .ag-pinned-right-header {
    //   border-left: none;
    // }

    .ag-pinned-left-cols-container {
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.06);
    }

    .ag-pinned-right-header,
    .ag-pinned-right-cols-container {
      padding-left: 0;
      left: 0;
    }

    .ag-pinned-right-cols-container {
      border-left: none;
    }

    .ag-cell-expandable {
      .ag-icon::before {
        font-weight: 600;
        font-size: 20px;
      }
    }

    .ag-row-level-1 {
      .extension-detail-container {
        margin: 0 0 0 50px;
      }
    }

    .ag-sticky-top-container,
    .ag-sticky-top {
      z-index: 1;
    }

    .group {
      overflow: visible;
      border-right: none !important;
    }

    .ag-overlay {
      pointer-events: auto !important;
      top: 48px !important;
      height: 70% !important
    }

    //Uncomment to hide Grid panels button
    // .ag-side-buttons {
    //   display: none;
    // }

    .ag-tool-panel-wrapper {
      .ag-react-container {
        width: inherit;
      }
    }

    .ag-column-panel {
      margin: 40px 0 80px;
    }

    .indicator {
      position: absolute;
      top: 6px;
      right: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $primary-orange-05;
    }

    .comment-indicator {
      position: absolute;
      top: 6px;
      right: 4px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $primary-orange-05;
    }
  }
}

.wrapper {
  height: 200%;
}

.tiles {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.toolbar {
  display: flex;
}
