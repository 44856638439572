@import "../../../variables.scss";

.search {
  padding: 0;
  width: 360px;
}

.wrapper {
  position: relative;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
