.guidanceItem {
  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-top: 5px;

    span {
      cursor: pointer;
    }
  }
}

.modal {
  width: 1100px;
  height: 620px;
}

.editorContainer {
  height: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

:global .ap-modal .ck-content {
  height: inherit;
}

.addButton {
  align-self: flex-end;
  margin-bottom: 10px;
}

.pointsContainer,
.guidanceContainer {
  display: flex;
  flex-direction: column;
}

.editGuidanceContainer {
  margin-top: 10px;
  .points {
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-top: 8px;
  }
}
