@import "../../variables.scss";

.container {
  height: 48px !important;
  position: relative !important;
}

.control {
  border: none !important;
  box-shadow: inset 0 0 0 0.0625rem $color-background-border !important;
}

.focused {
  border: none !important;
  box-shadow: inset 0 0 0 0.0625rem $color-background-primary,
    0 0 0 0.25rem rgba($color-background-primary-rgb, $opacity-3) !important;
}

.option {
  padding: 12px 8px !important;
  border-radius: $border-radius-2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer !important;

  &:hover {
    background-color: $color-background-selected;
  }
}

.option-selected {
  background-color: $color-background-selected !important;
  color: $color-text-body !important;
}

.value-container {
  padding: 18px 2px 2px 6px !important;
}

.menu {
  box-shadow: $box-shadow-3 !important;
  z-index: 2 !important;
}

.menu-list {
  padding: 8px !important;
}

.indicators {
  padding-right: $spacing-5;
  cursor: pointer;
}

.error {
  background-color: rgba($color-text-error-rgb, $opacity-3) !important;
  box-shadow: inset 0 0 0 0.0625rem $color-text-error !important;
}
