@import "../../variables.scss";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $spacing-4;
  margin-bottom: $spacing-6;
}

.tiles {
  display: flex;
  gap: $spacing-4;
  margin-bottom: $spacing-6;
}

.truck {
  padding: 32px;

  .search {
    width: 304px;
    padding: 0;
  }
}

.table {
  td {
    vertical-align: middle;
  }
}

.tableWrapper {
  // border: 1px solid #D1D1D1;
  // border-radius: 8px;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  // :global {
  //   .ag-root-wrapper {
  //     border-top: none !important;
  //   }
  // }
}

.tableWrapperNotExpanded {
  height: calc(100vh - 376px);
}

.expanded {
  position: fixed;
  left: 0;
  top: 98px;
  width: 100vw;
  height: calc(100vh - 98px);
  z-index: 1;
}

.wrapper {
  position: relative;
  min-height: 200px;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.loading {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.empty {
  position: absolute;
  top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.emptyTable {
  height: 440px;
}
