@import "../../../../../variables.scss";

.inputs {
  display: flex;
  flex-direction: column;
  gap: $spacing-3;
  margin-bottom: $spacing-3;
}

.modal {
  width: 80%;
  :global .ap-modal-footer {
    margin-top: $spacing-3;
  }
}

.subcategories {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $spacing-4;
}
