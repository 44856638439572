.editTemplateName {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    > div {
        flex-grow: 1;
    }
}

.upload {
    :global .ap-fileupload-wrapper .ap-fileupload-footer {
      display: none;
    }
  }