@import "../../../../../variables.scss";

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $spacing-3;
  background-color: $neutral-01;
  padding: $spacing-6;
  border: 1px solid $border-color-1;
  border-radius: $spacing-2;
  cursor: pointer;

  &:hover {
    box-shadow: $box-shadow-2;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  color: $neutral-01;
  margin-bottom: $spacing-3;
}

.text {
  text-align: center;
}
