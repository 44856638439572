@import "../../variables.scss";

.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overlay {
  top: 0;
  left: 0;
  opacity: 0.7;
  background-color: $neutral-01;
  z-index: 99;
}

.overlayPage {
  position: fixed;
}

.overlayParent {
  position: absolute;
}
