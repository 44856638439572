@import "../../variables.scss";

.page {
  border: 1px solid $neutral-05;
  margin-bottom: 12px;

  & span {
    letter-spacing: 0;
  }
}
