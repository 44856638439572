@import "../../../../../variables.scss";

.modal {
  width:$default-modal-width;
  overflow: visible !important;

  :global .ap-modal-body {
    overflow: visible;
  }

  :global .ap-modal-footer {
    margin-top: $spacing-3;
  }
}
