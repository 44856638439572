@import '../../../../variables.scss';

.text {
  font-weight: $font-weight-2;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
