.container {
    display: flex;
    flex-direction: column;
}

.saveBtn {
    align-self: flex-end;
}

.settingsContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .settingItem {
        gap: 20px;
    }
}